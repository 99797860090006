<template>
  <div v-loading="isLoading" class="px-3 py-4 txt-grey-900 fs-14 fw-500">
    <div class="bg-white rounded p-3 h-auto overflow-auto">
      <div class="flex items-center mb-3">
        <span class="title text-2xl text-black mr-2">{{ $t("health_book_page.lbl_book_title") }}</span>
        <!-- <template v-if="isHasEHCSync">
          <el-tooltip class="item" effect="dark" content="Đồng bộ dữ liệu từ EHC" placement="top">
            <el-button
              size="small"
              :class="{
    disabled: isSyncing,
  }"
              :disabled="isSyncing"
              @click="handleSyncEHC()"
            >
              <span v-if="!isSyncing">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-refresh-cw"
                >
                  <polyline points="23 4 23 10 17 10" />
                  <polyline points="1 20 1 14 7 14" />
                  <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
                </svg>
              </span>
              <i v-else class="el-icon-loading text-xl" />
            </el-button>
          </el-tooltip>
        </template>-->
      </div>
      <!-- <div class="font-bold fs-24 fw-700 mb-3">{{ $t('health_book_page.lbl_book_title') }}</div> -->
      <div class="flex justify-between">
        <div class="w-1/2">
          <el-input
            :placeholder="$t('health_book_page.lbl_hd_search')"
            prefix-icon="el-icon-search"
            v-model="valueSearch"
            @input="getHealthRecords"
          ></el-input>
        </div>
        <!-- <el-button type="primary" class="text-sm" @click="createHealthRecord()">{{ $t('health_book_page.lbl_add_book')
        }}</el-button>-->
      </div>
      <el-table
        class="w-full mt-3"
        :data="healthRecords"
        @row-click="viewDetail"
        row-class-name="cursor-pointer"
      >
        <el-table-column>
          <template slot="header">
            {{
            $t("health_book_page.lbl_hd_patient_name")
            }}
          </template>
          <template slot-scope="scope">
            <span class>{{ scope.row.person && scope.row.person.name }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            {{
            $t("health_book_page.lbl_pid")
            }}
          </template>
          <template slot-scope="scope">
            <span class>
              {{
              scope.row.person ? scope.row.person.pid : ""
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            {{
            $t("health_book_page.lbl_hd_phone")
            }}
          </template>
          <template slot-scope="scope">
            <span class>{{ scope.row.person && scope.row.person.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            {{
            $t("health_book_page.lbl_hd_date")
            }}
          </template>
          <template slot-scope="scope">
            <span class>{{ formatDateDMY(scope.row.visited_at) }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            {{
            $t("health_book_page.lbl_admission_hospital_date")
            }}
          </template>
          <template slot-scope="scope">
            <span class>
              {{
              formatDateDMY(scope.row.person.admission_hospital_date)
              }}
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column>
          <template slot="header">
            {{ $t('health_book_page.lbl_hd_visit_reason') }}
          </template>
          <template slot-scope="scope">
            <span class="">{{ scope.row.visit_reason }}</span>
          </template>
        </el-table-column>-->
        <!-- <el-table-column>
          <template slot="header">
            {{ $t('health_book_page.lbl_hd_status') }}
          </template>
          <template slot-scope="scope">
            <span class="">{{ getStatus(scope.row.status) }}</span>
          </template>
        </el-table-column>-->
      </el-table>
      <AppPagination :paginationProps="pagination" @onChangePagination="getHealthRecords" />

      <!-- <Pagination
        v-show="healthRecord && healthRecord.meta && healthRecord.meta.total"
        :items="healthRecord"
        :current_page="current_page"
        @onRefresh="getHealthRecords"
      ></Pagination>-->
    </div>

    <ModalAddHR ref="ModalAddHR" @refresh="getHealthRecords" />
    <ModalViewHR ref="ModalViewHR" />
  </div>
</template>

<script>
import moment from 'moment'
import Pagination from '@/components/Pagination'
import ModalAddHR from './ModalAdd.vue'
import ModalViewHR from './ModalView.vue'
import router from '@/router'
import { AppPagination } from '@/components/Common'
import appUtils from '@/utils/appUtils'

export default {
  components: { Pagination, ModalAddHR, ModalViewHR, AppPagination },
  name: 'HealthRecords',
  data () {
    return {
      healthRecord: [],
      valueSearch: '',
      current_page: 1,
      params: {
        page: 1
      },
      statuses: [
        { id: 0, name: 'Chờ khám', class: 'text-warning' },
        { id: 1, name: 'Đang khám', class: 'text-warning' },
        { id: 2, name: 'Có kết luận', class: 'text-success' },
        { id: 3, name: 'Đã trả sổ ', class: 'text-danger' }
      ],
      pagination: {
        currentPage: 1,
        pageSize: 15,
        totalItems: 0
      },
      isLoading: false,
      isSyncing: false
    }
  },
  watch: {
    pagination: {
      handler (value) {
        this.$router.push({
          query: { page: value.currentPage, limit: value.pageSize }
        })
      }
    }
  },
  async created () {
    const results = await this.getHealthRecords()

    if (this.$route.query.id) {
      const obj = results.find(
        result => result.id === Number(this.$route.query.id)
      )
      if (!obj) return
      this.viewDetailById(obj)
    }
  },
  computed: {
    healthRecords: function () {
      return this.healthRecord
    },
    isHasEHCSync () {
      const settings = appUtils.isJSON(this.$globalClinic?.settings)
        ? JSON.parse(this.$globalClinic?.settings)
        : {}
      return !!settings?.ehc_config
    }
  },
  methods: {
    async getHealthRecords (data) {
      try {
        this.isLoading = true
        // this.current_page = parseInt(this.$route.query.page) || 1
        const param = {
          ...this.params,
          page:
            data?.currentPage ||
            this.$route.query.page ||
            this.pagination.currentPage,
          limit:
            Number(data?.pageSize) ||
            this.$route.query.limit ||
            this.pagination.pageSize,
          sort: 'created_at',
          sort_by: 'desc',
          clinic_id: this.$globalClinic?.id
        }
        if (this.valueSearch) param.search = this.valueSearch
        const results = await this.$rf
          .getRequest('DoctorRequest')
          .getHealthRecords(param)
          .then(res => {
            this.healthRecord = res?.data
            this.pagination = {
              totalItems: res?.meta?.total,
              pageSize: Number(res?.meta?.per_page),
              currentPage: res?.meta?.current_page || 1
            }
            return res?.data
          })
        return results
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    formatDateDMY (date) {
      return date && moment(date).format('HH:mm DD/MM/YYYY')
    },
    getStatus (index) {
      if (!index) return this.statuses[0].name
      return this.statuses[index].name
    },
    createHealthRecord (obj) {
      this.$refs.ModalAddHR.openDialog(obj)
    },
    viewDetail (obj) {
      // router.push({ path: `/health-records/list-patient/${obj.id}` })
      router.push({
        name: 'DetailCoverPage',
        params: { id: obj.person_id },
        query: { health_record_id: obj.id }
      })
      // this.$refs.ModalViewHR.openDialog(obj)
    },
    viewDetailById (obj) {
      this.$refs.ModalViewHR.openDialog(obj)
    },
    async handleSyncEHC () {
      const self = this
      try {
        self.isSyncing = true
        const params = {
          org_id: self.$globalClinic?.org_id,
          ws_id: self.$globalClinic?.id
        }
        await self.$rf
          .getRequest('EHCSyncRequest')
          .ehcSyncHealthRecords(params)
          .then(res => {
            if (!res?.data) return
            if (res?.data?.code === 401) {
              self.$message({
                type: 'error',
                message: self.$t(`Bạn không thể đồng bộ dữ liệu từ EHC`)
              })
              console.log(res)
              return
            }
            console.log(res?.data)
            self.getHealthRecords()
            self.$message({
              type: 'success',
              message: self.$t(`Đồng bộ dữ liệu thành công`)
            })
            self.isSyncing = false
          })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped></style>
